import backButtonService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/backButtonService';

function handleBootstrapModals() {
    const modals = document.querySelectorAll('.modal.show');
    modals.forEach((modal) => {
        modal.classList.remove('show');
        modal.dispatchEvent(new Event('hide.bs.modal'));
    });
}

document.addEventListener('shown.bs.modal', () => {
    backButtonService.addBackButtonListener(() => handleBootstrapModals());
});

document.addEventListener('hidden.bs.modal', () => {
    backButtonService.handlePopState();
    backButtonService.removeBackButtonListener();
});
