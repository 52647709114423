class BackButtonService {
    constructor() {
        this.handleBackButton = null;
        this.handlePopState = this.handlePopState.bind(this);
        this.isModalOpen = false;
        this.isHandlingPopState = false;
    }

    addAndReplaceWindowHistory() {
        if (!window.history.state || window.history.state !== 'modal') {
            window.history.pushState('modal', null, window.location.href);
        } else {
            window.history.replaceState('modal', null, window.location.href);
        }
        this.isModalOpen = true;
        if (document.activeElement) {
            document.activeElement.blur();
        }
        document.body.focus();
    }

    addBackButtonListener(handleBackButton) {
        this.handleBackButton = handleBackButton;
        if (!this.isModalOpen && !this.isHandlingPopState) {
            this.addAndReplaceWindowHistory();
        }
        window.removeEventListener('popstate', this.handlePopState);
        window.addEventListener('popstate', this.handlePopState);
    }

    addBackButtonListenerWithoutCheckingPopStateHandler(handleBackButton) {
        this.handleBackButton = handleBackButton;
        this.addAndReplaceWindowHistory();
        window.removeEventListener('popstate', this.handlePopState);
        window.addEventListener('popstate', this.handlePopState);
    }

    removeBackButtonListener() {
        window.removeEventListener('popstate', this.handlePopState);
        this.isModalOpen = false;
    }

    handlePopState() {
        if (this.isHandlingPopState) {
            return;
        }
        this.isHandlingPopState = true;
        if (this.handleBackButton) {
            this.handleBackButton();
        }
        this.isModalOpen = false;
        this.isHandlingPopState = false;
    }
}

export default new BackButtonService();
